import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { SquareUserRound, BarChart as ChartIcon } from 'lucide-react';
import { useSelector } from 'react-redux';

const CHART_CONFIG = {
    colors: {
      default: '#3619c8',    // deep blue '#1e40af'
      hover: '#690eab',      // deep purple '#3730a3' #f7cf06
      selected: '#f7cf06',   // bright indigo #4f46e5
      icon: '#fbbf24',       // yellow for SquareUserRound
      text: '#9CA3AF',       // gray for labels
      statBlock: '#1e1d22', // stats background (equivalent to bg-gray-800)
      statBlockHover: '#1e1d22' // stats hover (equivalent to bg-indigo-900)
    },
    bars: {
      width: 80,             // width of each bar
      minHeight: 2,          // minimum height for very small values
      cornerRadius: 10,       // rounded corners
      gap: 1,               // gap between bars
      strokeWidth: 1         // border width
    },
    icons: {
      size: 24,             // size of the SquareUserRound icon
      offsetY: 30,          // distance above the bar
      textOffsetY: 10       // distance between icon and text
    },
    margins: {
      top: 40,
      right: 30,
      left: 20,
      bottom: 20
    }
  };

  const StatBlock = ({ label, value, userValue = null, unit = '' }) => {
    const [isHovered, setIsHovered] = useState(false);
  
    const formatValue = (val) => {
      if (val === null || val === undefined) return null;
      return typeof val === 'number' && !Number.isInteger(val) 
        ? Number(val).toFixed(2) 
        : val.toLocaleString();
    };
  
    return (
      <div 
        className="flex flex-col items-center justify-center p-4 rounded-lg min-h-[120px] bg-gray-700 bg-opacity-50 text-center filter grayscale hover:grayscale-0 duration-300 hover:shadow-[0_0_30px_rgba(255,191,50,0.8)] backdrop-blur-sm"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* Label */}
        <span className="text-base text-gray-200 mb-2 font-medium">{label}</span>
        
        {/* All users stats */}
        <div className="flex flex-col items-center mb-3">
          <span className="text-sm text-gray-300 mb-1">All responses</span>
          <span className="text-2xl font-bold text-white">
            {formatValue(value)}{unit}
          </span>
        </div>
  
        {/* User stats - only show if userValue exists */}
        {userValue !== null && (
          <div className="flex flex-col items-center">
            <span className="text-sm text-gray-300 mb-1">Your response</span>
            <span className="text-xl font-bold text-indigo-500">
              {formatValue(userValue)}{unit}
            </span>
          </div>
        )}
      </div>
    );
  };

const CustomBar = (props) => {
    const { x, y, width, height, fill, isUserBar, username } = props;
    const [isHovered, setIsHovered] = useState(false);
    
    const ICON_SIZE = 24;
    const ICON_OFFSET = 50;  // Increased vertical spacing
    const TEXT_OFFSET = 40;  // Space between icon and text
    
    return (
      <g>
        <rect
          x={x}
          y={y}
          width={Math.max(width, CHART_CONFIG.bars.width)}
          height={Math.max(height, CHART_CONFIG.bars.minHeight)}
          fill={isHovered ? CHART_CONFIG.colors.hover : fill}
          rx={CHART_CONFIG.bars.cornerRadius}
          ry={CHART_CONFIG.bars.cornerRadius}
          stroke="#1e293b"
          strokeWidth={CHART_CONFIG.bars.strokeWidth}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{ transition: 'fill 0.2s' }}
        />
        {isUserBar && (
          <>
            <SquareUserRound
              x={x + (width/2) - (ICON_SIZE/2)}
              y={y - ICON_OFFSET}
              width={ICON_SIZE}
              height={ICON_SIZE}
              style={{ color: CHART_CONFIG.colors.icon }}
            />
            <text
              x={x + width/2}
              y={y - ICON_OFFSET + TEXT_OFFSET}
              textAnchor="middle"
              fill={CHART_CONFIG.colors.text}
              fontSize={14}
              fontWeight="500"
            >
              {username || 'You'}
            </text>
          </>
        )}
      </g>
    );
};

const HistogramSelect = ({ value, onChange, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="px-4 py-2 text-sm text-gray-400 hover:text-yellow-400 
                 font-medium px-4 py-2 rounded-lg border border-gray-600
                 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
                 transition-colors duration-200 appearance-none cursor-pointer
                 flex items-center justify-between min-w-[200px]"
      >
        <span>{value}</span>
        <svg 
          className={`w-4 h-4 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
        >
          <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-md shadow-lg py-1 z-10 border border-gray-700">
          {options.map((option) => (
            <div
              key={option}
              className="block px-4 py-2 text-sm text-gray-400 hover:bg-gray-700 
                        hover:text-yellow-400 transition-colors duration-300 cursor-pointer"
              onClick={() => {
                onChange(option);
                setIsOpen(false);
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="bg-gray-800 p-3 rounded-lg border border-gray-700">
        <p className="text-gray-300">Range: {data.range}</p>
        <p className="text-gray-300">Share: {data.share.toFixed(1)}%</p>
      </div>
    );
  }
  return null;
};

const formatHistogramData = (bins, counts) => {
    if (!bins || !counts || bins.length < 2) return [];
    
    const total = counts.reduce((sum, count) => sum + count, 0);
    return counts.map((count, i) => ({
      range: `${Math.round(bins[i])} - ${Math.round(bins[i + 1])}`,
      share: (count / total) * 100,
      count,
      binStart: bins[i],
      binEnd: bins[i + 1]
    }));
  };

  const QuestStats = ({ stats, userSubmission, isActive, quest }) => {
    const [selectedHistogram, setSelectedHistogram] = useState('Response length');
    const user = useSelector(state => state.auth.user);

    const userTotalSpecks = userSubmission && userSubmission.words_len > 0 
    ? (quest.is_active 
        ? quest.base_reward 
        : (quest.base_reward + (userSubmission.value_reward || 0)))
    : 0;

    const histogramData = useMemo(() => {
        const data = (() => {
            switch (selectedHistogram) {
                case 'Response length':
                    return formatHistogramData(
                        stats.length_histogram.bins,
                        stats.length_histogram.counts
                    );
                case 'Unique words added':
                    return formatHistogramData(
                        stats.words_histogram.bins,
                        stats.words_histogram.counts
                    );
                case 'Information value':
                    return formatHistogramData(
                        stats.value_histogram.bins,
                        stats.value_histogram.counts
                    );
                default:
                    return [];
            }
        })();

        return data;
    }, [selectedHistogram, stats]);
  
  const histogramOptions = useMemo(() => {
    const options = ['Response length', 'Unique words added'];
    if (!isActive) {
      options.push('Information value');
    }
    return options;
  }, [isActive]);

  const getUserBin = useCallback((data) => {

    if (!userSubmission || !data || data.length === 0) {
        console.groupEnd();
        return null;
    }

    let value;
    switch (selectedHistogram) {
        case 'Response length':
            value = userSubmission.words_len;
            break;
        case 'Unique words added':
            value = userSubmission.words_added;
            break;
        case 'Information value':
            value = userSubmission.value_reward;
            break;
        default:
            console.groupEnd();
            return null;
    }

    if (value === undefined || value === null) {
        console.groupEnd();
        return null;
    }

    const binIndex = data.findIndex(item => {
        const start = parseFloat(item.binStart);
        const end = parseFloat(item.binEnd);
        const result = value >= start && value < end;
        return result;
    });
    console.groupEnd();
    return binIndex >= 0 ? binIndex : null;
}, [userSubmission, selectedHistogram]);

const userBinIndex = useMemo(() => {
    const result = getUserBin(histogramData);
    return result;
}, [histogramData, getUserBin]);

  const renderBar = (props) => {
    const isUserBar = props.index === userBinIndex;
    
    return (
      <CustomBar
        {...props}
        fill={isUserBar ? CHART_CONFIG.colors.selected : CHART_CONFIG.colors.default}
        isUserBar={isUserBar}
        username={user?.username}
      />
    );
  };

  return (
    <div className="mt-8 p-6 bg-gray-800 bg-opacity-80 rounded-xl border border-color-yellow-700">
      <h3 className="text-xl font-semibold text-indigo-500 flex items-center">
        <ChartIcon className="mr-2" />
        Quest Statistics
      </h3>
      <p className="mt-1 text-md text-gray-400 italic">
        Statistics are updated automatically every minute.
      </p>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <StatBlock 
          label="Total Specks Gained"
          value={stats.total_specks_gained}
          userValue={userTotalSpecks}
        />
        <StatBlock 
          label="Avg Response Length"
          value={Math.round(stats.avg_response_length)}
          userValue={userSubmission?.words_len}
          unit=" words"
        />
        <StatBlock 
          label="Total Unique Words Added"
          value={stats.total_unique_words}
          userValue={userSubmission?.words_added}
          unit=" added"
        />
        <StatBlock 
          label="Avg Entropy Reduction"
          value={stats.avg_entropy_change}
          userValue={userSubmission?.entropy_reduction}
        />
      </div>

      <div className="mt-6">
        <div className="flex justify-end items-center mb-8">
          <HistogramSelect
            value={selectedHistogram}
            onChange={setSelectedHistogram}
            options={histogramOptions}
          />
        </div>
        
        <div className="mt-6"> {/* Added significant top margin */}
          <div className="h-80 w-full">
          <ResponsiveContainer>
            <BarChart 
              data={histogramData} 
              margin={{...CHART_CONFIG.margins, top: 60}}
              barCategoryGap={CHART_CONFIG.bars.gap}
            >
              <XAxis 
                dataKey="range" 
                stroke={CHART_CONFIG.colors.text}
                fontSize={12}
                tickLine={false}
              />
              <YAxis 
                stroke={CHART_CONFIG.colors.text}
                fontSize={12}
                tickLine={false}
                label={{ 
                  value: 'Share, %', 
                  angle: -90, 
                  position: 'insideLeft',
                  style: { fill: CHART_CONFIG.colors.text }
                }}
              />
              <Tooltip 
                content={<CustomTooltip />}
                cursor={false}
              />
              <Bar 
                dataKey="share"
                shape={renderBar}
                barSize={CHART_CONFIG.bars.width}
                minPointSize={CHART_CONFIG.bars.minHeight}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  </div>
  );
};

export default QuestStats;