import React from "react";
import { store, persistor, history } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { Route, Routes } from "react-router-dom";
import { HistoryRouter } from "redux-first-history/rr6";
import { ToastContainer } from "react-toastify";
import ScrollToTop from './components/ui/ScrollToTop';
import ScrollToTopOnRoute from './components/ui/ScrollToTopOnRoute';
import NavBar from "./components/NavBar";
import SpaceBackground from "./components/SpaceBackground";
import Home from "./components/Home";
import Signup from "./components/signup/Signup";
import Login from "./components/login/Login";
import QuestsDashboard from "./components/quests/QuestsDashboard";
import Feed from "./components/feed/Feed";
import Quest from "./components/quests/Quest";
import UserLeaderboard from "./components/leaderboards/UserLeaderboard";
import UserProfile from "./components/profile/UserProfile"
import SettingsPage from "./components/SettingsPage";
import requireAuth from "./utils/RequireAuth";
import './index.css';

import axios from "axios";
if (window.location.origin === "http://localhost:3000") {
  axios.defaults.baseURL = "http://127.0.0.1:8000";
} else {
  axios.defaults.baseURL = window.location.origin;
}

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Token ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const App = () => {
  const AuthenticatedUserProfile = requireAuth(UserProfile);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HistoryRouter history={history}>
          <ScrollToTopOnRoute />
          <div className="relative min-h-screen font-sans">
            <SpaceBackground />
            <NavBar />
            <div className="container mx-auto px-4 pt-24 pb-8">
              <Routes>
                <Route path="/signup" element={<Signup />} />
                <Route path="/login" element={<Login />} />
                <Route path="/profile" element={<AuthenticatedUserProfile />} />
                <Route path="/u/:username" element={<UserProfile />} />
                <Route path="/quests" element={<QuestsDashboard />} />
                <Route path="/feed" element={<Feed />} />
                <Route path="/leaderboard" element={<UserLeaderboard />} />
                <Route path="/quest/:questSlug" element={<Quest />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route exact path="/" element={<Home />} />
              </Routes>
            </div>
            <ScrollToTop />
          </div>
        </HistoryRouter>
      </PersistGate>
      <ToastContainer hideProgressBar={true} newestOnTop={true} />
    </Provider>
  );
};

export default App;