
import React from 'react';

const TripleToggle = ({ value, options, onChange }) => {
  return (
    <div className="flex items-center h-10 bg-gray-800/50 rounded-lg p-1 shadow-lg">
      {Object.entries(options).map(([key, label]) => (
        <button
          key={key}
          onClick={() => onChange(key)}
          className={`
            flex-1 h-8 px-4 rounded-md text-sm font-medium transition-all duration-200
            ${value === key 
              ? 'bg-purple-700 text-white shadow-lg' 
              : 'text-gray-400 hover:text-gray-200'
            }
          `}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default TripleToggle;