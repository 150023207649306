import React from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { toast } from 'react-toastify';
import { submitQuest } from '../components/quests/QuestsActions';
import { DEFAULT_FEED_PRIVACY } from '../components/quests/QuestDetail';
import axios from 'axios';
import { setToken, setCurrentUser, unsetCurrentUser } from '../components/login/LoginActions';

export const handlePostAuthActions = async (dispatch, navigate) => {
  const guestAnswer = localStorage.getItem('guestAnswer');
  const guestQuestId = localStorage.getItem('guestQuestId');
  const guestQuestSlug = localStorage.getItem('guestQuestSlug');
  const guestPrivacy = localStorage.getItem('guestPrivacy') || DEFAULT_FEED_PRIVACY;

  if (guestAnswer && guestQuestId) {
    try {
      await dispatch(submitQuest(guestQuestId, guestAnswer, guestPrivacy));
      // Clear localStorage
      ['guestAnswer', 'guestQuestId', 'guestQuestSlug', 'guestPrivacy'].forEach(key => 
        localStorage.removeItem(key)
      );
      
      if (guestQuestSlug) {
        navigate(`/quest/${guestQuestSlug}`);
        return;
      }
    } catch (error) {
      console.error('Failed to submit guest quest:', error);
      toast.error("Failed to submit your answer. Please try again from the quest page.");
    }
  }
  navigate('/');
};

export const handleGoogleAuth = async (credentialResponse, dispatch, navigate) => {
  try {
    const response = await axios.post('/auth/social/', {
      provider: 'google-oauth2',
      access_token: credentialResponse.credential
    });

    if (response.data) {
      dispatch(setToken(response.data.token));
      dispatch(setCurrentUser(response.data.user));
      await handlePostAuthActions(dispatch, navigate);
    }
  } catch (error) {
    toast.error('Failed to authenticate with Google');
    console.error('Google auth error:', error.response?.data || error);
    dispatch(unsetCurrentUser());
  }
};

// Shared component for Google auth button
export const GoogleAuthButton = ({ onSuccess }) => (
  <div className="w-full flex justify-center mb-4">
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <GoogleLogin
        onSuccess={onSuccess}
        onError={() => toast.error('Google Authentication Failed')}
        theme="filled_black"
        shape="pill"
        width="280px"
      />
    </GoogleOAuthProvider>
  </div>
);