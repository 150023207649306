import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { signupNewUser } from './SignupActions';
import { login } from '../login/LoginActions';
import { DEFAULT_FEED_PRIVACY } from '../quests/QuestDetail';
import ReCAPTCHA from 'react-google-recaptcha';
import { submitQuest } from '../quests/QuestsActions';
import SpaceBackground from '../SpaceBackground';
import { ArrowRight, UserPlus } from 'lucide-react';
import { toast } from 'react-toastify';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { handleGoogleAuth, handlePostAuthActions, GoogleAuthButton } from '../../utils/AuthUtils';
import SavedAnswerNotification from '../ui/SavedAnswerNotification';

const Signup = ({ signupNewUser, login, submitQuest, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    re_password: ''
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();  // Add this line

  const { username, password, re_password } = formData;
  const [captchaToken, setCaptchaToken] = useState('');

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password === re_password) {
      try {
        await signupNewUser({...formData, captcha: captchaToken });
        const loginSuccess = await login({ username, password, captcha: captchaToken });
        if (loginSuccess) {
          const guestAnswer = localStorage.getItem('guestAnswer');
          const guestQuestId = localStorage.getItem('guestQuestId');
          const guestQuestSlug = localStorage.getItem('guestQuestSlug');
          const guestPrivacy = localStorage.getItem('guestPrivacy') || DEFAULT_FEED_PRIVACY;
          if (guestAnswer && guestQuestId) {
            await submitQuest(guestQuestId, guestAnswer, guestPrivacy);
            localStorage.removeItem('guestAnswer');
            localStorage.removeItem('guestQuestId');
            localStorage.removeItem('guestQuestSlug');
            localStorage.removeItem('guestPrivacy');
          }
          if (guestQuestSlug) {
            navigate(`/quest/${guestQuestSlug}`);
            return;
          }
          navigate('/');
        } else {
          toast.error("Error with login after signup");
        }
      } catch (error) {
        toast.error("Error during signup: " + error.message);
      }
    } else {
      toast.error("Passwords don't match");
    }
  };

  if (isAuthenticated) {
    navigate('/quests');
    return null;
  }

  const handleGoogleSuccess = async (credentialResponse) => {
    await handleGoogleAuth(credentialResponse, dispatch, navigate);
  };

  return (
    <>
      <SpaceBackground />
      <div className="relative flex items-center justify-center min-h-screen text-white">
        <div className="relative w-full max-w-md mx-4">
            {/* Main container */}
            <div className="bg-gray-800/80 backdrop-blur-sm rounded-xl p-8">
              <h1 className="text-3xl font-bold mb-8 bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
                Join the Abyss
              </h1>
              {localStorage.getItem('guestAnswer') && <SavedAnswerNotification />}
              {/* Google Sign Up Button */}
              <div className="mb-6">
                <GoogleAuthButton onSuccess={handleGoogleSuccess} />

                <div className="relative text-center">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-600"></div>
                  </div>
                  <div className="relative">
                    <span className="px-2 text-sm text-gray-400 bg-gray-800/80">Or continue with</span>
                  </div>
                </div>
              </div>
              <form onSubmit={onSubmit} className="space-y-6">
                <div>
                  <label className="block text-md font-semibold mb-2 text-gray-300" htmlFor="username">
                    Username
                  </label>
                  <input
                    className="w-full bg-gray-700/50 border border-gray-600 rounded-lg py-2 px-3 
                             text-white-200 leading-tight focus:outline-none focus:border-purple-500 
                             focus:ring-2 focus:ring-purple-500/50 transition-all duration-300"
                    id="username"
                    type="text"
                    placeholder="Choose a username"
                    name="username"
                    value={username}
                    onChange={onChange}
                    required
                  />
                </div>
                <div>
                  <label className="block text-md font-semibold mb-2 text-gray-300" htmlFor="password">
                    Password
                  </label>
                  <input
                    className="w-full bg-gray-700/50 border border-gray-600 rounded-lg py-2 px-3 
                             text-white-200 leading-tight focus:outline-none focus:border-purple-500 
                             focus:ring-2 focus:ring-purple-500/50 transition-all duration-300"
                    id="password"
                    type="password"
                    placeholder="Enter your password"
                    name="password"
                    value={password}
                    onChange={onChange}
                    minLength="6"
                    required
                  />
                </div>
                <div>
                  <label className="block text-md font-semibold mb-2 text-gray-300" htmlFor="re_password">
                    Confirm Password
                  </label>
                  <input
                    className="w-full bg-gray-700/50 border border-gray-600 rounded-lg py-2 px-3 
                             text-white-200 leading-tight focus:outline-none focus:border-purple-500 
                             focus:ring-2 focus:ring-purple-500/50 transition-all duration-300"
                    id="re_password"
                    type="password"
                    placeholder="Confirm your password"
                    name="re_password"
                    value={re_password}
                    onChange={onChange}
                    minLength="6"
                    required
                  />
                </div>
                {/* Add the ReCAPTCHA component here */}
                <div className="mt-6 flex justify-center">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
                    onChange={handleCaptchaChange}
                    theme='dark'
                  />
                </div>
                <div className="flex items-center justify-between pt-2">
                  <button
                    className="px-6 py-2 text-sm font-medium text-white rounded-lg transition-all duration-300
                              bg-gradient-to-r from-purple-600 to-blue-600
                              hover:from-purple-500 hover:to-blue-500
                              hover:shadow-[0_0_20px_rgba(168,85,247,0.4)]
                              backdrop-blur-sm flex items-center"
                    type="submit"
                  >
                    <UserPlus className="mr-2" size={18} />
                    Sign Up
                  </button>
                  <Link
                    to="/login"
                    className="inline-flex items-center text-sm font-medium text-blue-600 
                              hover:text-purple-600 transition-colors duration-300"
                  >
                    Already have an account? <ArrowRight className="inline ml-1" size={16} />
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
    </>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { signupNewUser, login, submitQuest })(Signup);