import axios from 'axios';

export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

export const fetchUserProfile = () => async dispatch => {
  dispatch({ type: FETCH_PROFILE_REQUEST });

  try {
    const response = await axios.get('/api/v1/profile/', {
      params: {
        page: 1,
        per_page: 1
      }
    });

    dispatch({
      type: FETCH_PROFILE_SUCCESS,
      payload: response.data
    });
    return response.data;
  } catch (error) {
    let errorMessage = 'Failed to load profile';
    if (error.response) {
      if (error.response.status === 404) {
        errorMessage = 'User not found';
      } else if (error.response.status === 403) {
        errorMessage = 'This profile is private';
      }
    }

    dispatch({
      type: FETCH_PROFILE_FAILURE,
      payload: errorMessage
    });
    throw error;
  }
};