// components/SettingsPage.js
import React, { useState, useEffect } from 'react';
import { Shield, Info } from 'lucide-react';
import Toast from './ui/Toast';
import axios from 'axios';
import TripleToggle from './ui/TripleToggle';

const SettingsPage = () => {
  const [privateProfile, setPrivateProfile] = useState(false);
  const privacyOptions = {
    'PUBLIC': 'Public',
    'ANONYMOUS': 'Anonymous',
    'PRIVATE': 'Private'
  };
  const [feedPrivacy, setFeedPrivacy] = useState(privacyOptions.ANONYMOUS);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get('/api/v1/profile/');
        setPrivateProfile(response.data.private_profile);
        setFeedPrivacy(response.data.feed_privacy);
      } catch (error) {
        setToastMessage('Failed to load settings');
        setShowToast(true);
      }
    };
    fetchSettings();
  }, []);


  const handleProfilePrivacyToggle = async () => {
    try {
      const newState = !privateProfile;
      await axios.patch('/api/v1/profile/', {
        private_profile: newState
      });
      
      setPrivateProfile(newState);
      const message = newState 
        ? "Your profile is now private" 
        : "Your profile is now public";
      setToastMessage(message);
      setShowToast(true);
    } catch (error) {
      setToastMessage('Failed to update privacy settings');
      setShowToast(true);
    }
  };

  const handleFeedPrivacyChange = async (newPrivacy) => {
    try {
      await axios.patch('/api/v1/profile/', {
        feed_privacy: newPrivacy
      });
      
      setFeedPrivacy(newPrivacy);
      const messages = {
        [privacyOptions.PUBLIC]: "Your submissions will be public in the feed",
        [privacyOptions.ANONYMOUS]: "Your submissions will be anonymous in the feed",
        [privacyOptions.PRIVATE]: "Your submissions will not appear in the feed"
      };
      setToastMessage(messages[newPrivacy]);
      setShowToast(true);
    } catch (error) {
      setToastMessage('Failed to update feed privacy settings');
      setShowToast(true);
    }
  };


  return (
    <div className="min-h-screen text-white font-sans">
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-gray-800/30 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50">
          {/* Profile Privacy Section */}
          <div className="mb-12">
            <div className="flex items-center justify-between mb-4">
              <div>
                <h2 className="text-xl font-semibold mb-1">Profile Privacy</h2>
                <p className="text-gray-300 text-sm">Control access to your explorer profile</p>
              </div>
              <button
                onClick={handleProfilePrivacyToggle}
                className="relative inline-flex items-center h-6 rounded-full w-11 transition-all duration-300"
                style={{
                  backgroundColor: privateProfile ? '#7d1fc2' : 'rgba(55, 65, 81, 0.5)'
                }}
              >
                <span
                  className={`
                    inline-block w-4 h-4 transform transition-transform duration-300 
                    bg-white rounded-full shadow-lg
                    ${privateProfile ? 'translate-x-6' : 'translate-x-1'}
                  `}
                />
              </button>
            </div>

            <div className="bg-gray-800/50 rounded-lg p-4 flex items-start space-x-3">
              <Info size={20} className="text-blue-400 mt-1 flex-shrink-0" />
              <p className="text-gray-300 text-sm leading-relaxed">
                When your profile is private, other explorers won't be able to see your detailed information and quest history. 
                Your user name will still be visible on the leaderboards.
              </p>
            </div>
          </div>

          {/* Feed Visibility Section */}
          <div className="mb-12">
            <div className="mb-6">
              <h2 className="text-xl font-semibold mb-1">Feed Visibility</h2>
              <p className="text-gray-300 text-sm">Control how your quest responses appear in the global feed</p>
            </div>

            <div className="flex justify-center mb-6">
              <TripleToggle
                value={feedPrivacy}
                options={{
                  'PUBLIC': 'Public',
                  'ANONYMOUS': 'Anonymous',
                  'PRIVATE': 'Private'
                }}
                onChange={handleFeedPrivacyChange}
              />
            </div>

            <div className="bg-gray-800/50 rounded-lg p-6">
              <div className="grid grid-cols-3 gap-6">
                <div className="space-y-3">
                  <h3 className="font-semibold text-blue-400">Public</h3>
                  <p className="text-gray-300 text-sm leading-relaxed">
                    Your responses appear with your username in the feed. Other explorers will know that you gave those responses.
                  </p>
                </div>
                <div className="space-y-3">
                  <h3 className="font-semibold text-blue-400">Anonymous</h3>
                  <p className="text-gray-300 text-sm leading-relaxed">
                    Your responses appear in the feed without your username. Share your thoughts while maintaining privacy.
                  </p>
                </div>
                <div className="space-y-3">
                  <h3 className="font-semibold text-blue-400">Private</h3>
                  <p className="text-gray-300 text-sm leading-relaxed">
                    None of your responses will appear in the global feed. Perfect for those who prefer to explore quietly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {showToast && (
        <Toast 
          message={toastMessage} 
          type="success" 
          onClose={() => setShowToast(false)} 
        />
      )}
    </div>
  );
};

export default SettingsPage;