import React, { useEffect, useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import { fetchQuests } from './quests/QuestsActions';
import SpaceBackground from './SpaceBackground';
import AboutCosmicQuests from './AboutCosmicQuests';
import QuestDetail from './quests/QuestDetail';

const Home = ({ quests, fetchQuests, isAuthenticated, loading, error }) => {
  const aboutRef = useRef(null);
  const navigate = useNavigate();
  const [guestAnswer, setGuestAnswer] = useState('');
  const fetchQuestsStable = useCallback(() => {
    fetchQuests(true);
  }, [fetchQuests]);

  useEffect(() => {
    fetchQuestsStable(true);
    const savedAnswer = localStorage.getItem('guestAnswer');
    if (savedAnswer) {
      setGuestAnswer(savedAnswer);
    }
  }, [fetchQuestsStable]);

  const featuredQuest = quests?.results && quests.results.length > 0
  ? quests.results
      .filter(quest => {
        const now = new Date();
        const startDate = new Date(quest.active_since);
        const endDate = new Date(quest.active_to);
        
        return (
          quest.is_active && 
          startDate <= now && 
          now <= endDate
        );
      })
      .sort((a, b) => new Date(b.active_since) - new Date(a.active_since))[0]
  : null;

  const scrollToAbout = () => {
    aboutRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleGuestSubmit = (answer) => {
    localStorage.setItem('guestAnswer', answer);
    localStorage.setItem('guestQuestId', featuredQuest.id);
    navigate('/signup');
  };

  const renderContent = () => {
    if (loading) {
      return <p>Loading quests...</p>;
    }

    if (error) {
      return <p>Error loading quests: {error}</p>;
    }

    if (featuredQuest) {
      return (
        <QuestDetail 
          quest={featuredQuest} 
          onHome={true} 
          isAuthenticated={isAuthenticated}
          guestAnswer={guestAnswer}
          onGuestSubmit={handleGuestSubmit}
          previousAnswer={featuredQuest.user_submission}
        />
      );
    }

    return <p className="text-gray-400">No active quests available at the moment.</p>;
  };

  
  return (
    <>
      <SpaceBackground />
      <div className="relative min-h-screen text-gray-200 overflow-y-auto">

        <div className="min-h-screen flex flex-col items-center pt-16">
        <h1 className="text-4xl font-bold mb-6 flex items-center justify-center">
          <span className="bg-gradient-to-r from-yellow-500 to-red-400 bg-clip-text text-transparent transition-opacity">
            Latest in the Abyss
          </span>
          <span className="ml-2">🎄☃️🎄</span>
        </h1>
          {renderContent()}
          <div className="text-center animate-bounce cursor-pointer mt-8" onClick={scrollToAbout}>
            <p className="text-sm text-gray-400">Scroll to find out more</p>
            <ChevronDown className="mx-auto mt-2 text-gray-400" />
          </div>
        </div>

        <div ref={aboutRef}>
          <AboutCosmicQuests />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  quests: state.quests.quests,
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.quests.loading,
  error: state.quests.error
});

export default connect(mapStateToProps, { fetchQuests })(Home);