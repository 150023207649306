import { 
  GET_QUESTS, 
  SUBMIT_QUEST,
  FETCH_QUESTS_START,
  FETCH_QUESTS_SUCCESS,
  FETCH_QUESTS_FAILURE,
  OPEN_QUEST,
  FETCH_USER_RESPONSE,
  FETCH_SINGLE_QUEST_SUCCESS,
  LIKE_QUEST,
  ADD_QUEST_VIEW,
  SET_ACTIVE_TAB,
} from "./QuestsTypes";

const initialState = {
  quests: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  finishedQuests: [], // Add this to track finished quests
  activeTab: "active", // Add initial value
  error: null,
  loading: false
};

export default function questsReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_QUESTS_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_QUESTS_SUCCESS:
    case GET_QUESTS:
      return {
        ...state,
        quests: action.payload,
        error: null,
        loading: false
      };
    case FETCH_SINGLE_QUEST_SUCCESS: {
      const exists = state.quests.results?.some(q => q.id === action.payload.id);
      return {
        ...state,
        quests: {
          ...state.quests,
          results: exists
            ? state.quests.results.map(q => 
                q.id === action.payload.id ? action.payload : q
              )
            : [...(state.quests.results || []), action.payload]
        },
        loading: false,
        error: null
      };
    }
    case FETCH_QUESTS_FAILURE:
      return {
        ...state,
        quests: initialState.quests,
        error: action.payload,
        loading: false
      };
      case LIKE_QUEST:
        return {
          ...state,
          quests: {
            ...state.quests,
            results: (state.quests.results || []).map(quest => 
              quest.id === action.payload.questId
                ? {
                    ...quest,
                    is_liked: action.payload.isLiked,
                    likes_count: action.payload.likesCount
                  }
                : quest
            )
          },
          finishedQuests: (state.finishedQuests || []).map(quest =>
            quest.id === action.payload.questId
              ? {
                  ...quest,
                  is_liked: action.payload.isLiked,
                  likes_count: action.payload.likesCount
                }
              : quest
          )
        };
    case ADD_QUEST_VIEW:
      return {
        ...state,
        quests: {
          ...state.quests,
          results: state.quests.results.map(quest =>
            quest.id === action.payload.questId
              ? { ...quest, views_count: (quest.views_count || 0) + 1 }
              : quest
          )
        }
      };
    case OPEN_QUEST:
      return {
        ...state,
        quests: {
          ...state.quests,
          results: state.quests.results.map(quest => 
            quest.id === action.payload.id ? { ...quest, ...action.payload } : quest
          )
        },
        error: null
      };
    case SUBMIT_QUEST:
      return {
        ...state,
        quests: {
          ...state.quests,
          results: state.quests.results.map(quest => 
            quest.id === action.payload.questId 
              ? { 
                  ...quest, 
                  user_submission: {
                    ...quest.user_submission,  // Preserve existing submission data
                    text: action.payload.userSubmission,
                    feed_privacy: action.payload.feed_privacy
                  }
                }
              : quest
          )
        },
        error: null
      };
    case FETCH_USER_RESPONSE:
      return {
        ...state,
        quests: {
          ...state.quests,
          results: state.quests.results.map(quest => 
            quest.id === action.payload.questId 
              ? { ...quest, user_submission: action.payload.userResponse } 
              : quest
          )
        },
        error: null
      };
    case 'ADD_FINISHED_QUESTS':
        return {
          ...state,
          finishedQuests: [
            ...state.finishedQuests,
            ...action.payload.filter(quest => 
              quest && quest.id && !state.finishedQuests.some(q => q.id === quest.id)
            )
          ]
        };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload
      };
    default:
      return state;
  }
}