import React from 'react';
import { Info, ChartCandlestick, BotMessageSquare, Brain, Drama, Gem, Send, MessageCircle, Mail } from 'lucide-react';

const AboutCosmicQuests = () => {
  return (
    <div className="max-w-6xl mx-auto px-8 bg-gray-800 bg-opacity-25 p-8 rounded-lg font-sans">
      <h2 className="text-3xl font-bold mb-12 bg-indigo-500 bg-clip-text text-transparent flex items-center justify-center">
        <Info className="mr-3 text-indigo-500" />
        About Abyss
      </h2>
      <p className="text-gray-300 mb-6 text-lg text-justify indent-8 leading-relaxed tracking-wide">
        Abyss is an interactive Human-AI platform that enables deep learning in both parties. 
        Explorers are offered to complete quests in exchange for a reward which is computed based on the information value that the system gets.
        We hope that those quests will also be educational and stimulating for your beautiful minds. 
      </p>
      <p className="text-gray-300 mb-6 text-lg text-justify indent-8 leading-relaxed tracking-wide">
        It is of course totally fine to look something up on the Internet, or to use any resource you need including AI assistants.
        However, since Abyss aims to construct a sort of compass for the AI of the future, purely synthetic responses wouldn't be valuable here; and on our side we will do our best to detect it.
        Similarly, we will do our best to justly reward the original, thoughtful answers. While AI tools can be valuable companions in crafting your responses, focus on contributing your unique ideas and experiences. 
        It is your chance to leave your fingerprint among the billions and billions of artificial neurons after all.
        </p>
        <p className="text-gray-300 mb-6 text-lg text-justify indent-8 leading-relaxed tracking-wide">
        Embark on a journey of exploration, where your creativity and uniqueness are your most valuable assets! 🚀        
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 bg-gray-800 bg-opacity-15 p-8 rounded-lg">
        <InfoBlock
          icon={<Brain className="text-green-400" />}
          title="Complete quests to earn rewards"
          description={
            <ul className="list-disc pl-5 space-y-2">
              <li>Engage with daily quests on diverse topics</li>
              <li>Earn instant rewards upon quest completion</li>
              <li>Receive bonus rewards computed based on your answer's information value</li>
            </ul>
          }
        />
        <InfoBlock
          icon={<Drama className="text-purple-400" />}
          title="You decide what to share"
          description="Embrace the power of choice in the Abyss. We value your privacy and put you in control. Craft your Abyss experience with the freedom to keep your profile public or mysteriously private."
        />
        <InfoBlock
          icon={<Gem className="text-blue-400" />}
          title="$Specks: Your currency in the Abyss"
          description="Get ready for $SPECKS – the rewards token of the Abyss! As we transition from open Beta, your efforts won't go unnoticed - early explorers will not only keep the accumulated balance but also receive other exlusive rewards."
        />
        <InfoBlock
          icon={<BotMessageSquare className="text-yellow-400" />}
          title="AI, You & I"
          description={
            <ul className="list-disc pl-5 space-y-2">
              <li><strong>Shape the Future of AI:</strong> Make a conscious contribution to tomorrow's artificial intelligence</li>
              <li><strong>Your Voice Matters:</strong> Your unique responses become an integral part of the Abyss</li>
              <li><strong>Ethical Participation:</strong> By engaging, you're actively consenting to be part of this revolutionary journey</li>
            </ul>
          }
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-12">
        <InfoBlock
          icon={<MessageCircle className="text-indigo-500" />}
          title="Stay connected"
          description={
            <div className="flex flex-col space-y-2">
            <SocialLink imageSrc="/discord_logo.png" name="Discord" url="https://discord.gg/DpsZucuDnx" isNew={true} />
            <SocialLink icon={Send} name="Telegram" url="https://t.me/abyss_ai_ink" isNew={true} />
            <SocialLink imageSrc="/x_logo.png" name="X" url="https://x.com/abyss_ai_ink" />
            <SocialLink 
              icon={Mail} 
              name="Contact Us" 
              url={`mailto:${atob('YnVzaW5lc3NAYWJ5c3MuaW5r')}`} 
            />
          </div>
          }
        />
        <a 
          href="https://explorer.solana.com/address/abyK2wZctEz6r6VoVxgC7oZTCgzcA9c82p2X4EBAiMF"
          target="_blank"
          rel="noopener noreferrer"
          className="block transition-transform duration-300 hover:scale-102 hover:shadow-lg"
          aria-label="View Specks token on Solana Explorer"
        >
          <div className="bg-gray-800 bg-opacity-80 p-6 rounded-lg shadow-lg flex flex-col h-full hover:bg-opacity-90 transition-all duration-300">
            <div className="flex items-center mb-4">
              <div className="text-3xl mr-3">
                <ChartCandlestick className="text-green-400" />
              </div>
              <h3 className="text-xl font-semibold text-white">Specks Info</h3>
            </div>
            <div className="text-gray-300 flex-grow">
              <div className="text-center">
                <p className="text-2xl font-bold text-indigo-500 animate-pulse">To Be Announced</p>
                <p className="mt-2 text-gray-300">SPECKS are traveling to you at the speed of light!</p>
                <div className="mt-4 flex justify-center space-x-2">
                  {[1, 2, 3].map((_, index) => (
                    <div 
                      key={index} 
                      className="w-3 h-3 bg-indigo-500 rounded-full animate-bounce" 
                      style={{ animationDelay: `${index * 0.2}s` }} 
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

const InfoBlock = ({ icon, title, description }) => (
  <div className="bg-gray-800 bg-opacity-80 p-6 rounded-lg shadow-lg flex flex-col">
    <div className="flex items-center mb-4">
      <div className="text-3xl mr-3">{icon}</div>
      <h3 className="text-xl font-semibold text-white">{title}</h3>
    </div>
    {/* If description is a string, apply text formatting. Otherwise, render as is (for cases with lists/complex content) */}
    <div className="text-gray-300 flex-grow leading-relaxed tracking-wide">
      {typeof description === 'string' ? (
        <p className="text-justify indent-8">
          {description}
        </p>
      ) : (
        description
      )}
    </div>
  </div>
);

const SocialLink = ({ icon: Icon, imageSrc, name, url, isNew }) => (
  <div className="relative">
    <a 
      href={url} 
      target="_blank" 
      rel="noopener noreferrer" 
      className="flex items-center space-x-2 text-gray-300 hover:text-blue-400 transition duration-300 group"
    >
      {imageSrc ? (
        <img 
          src={imageSrc} 
          alt={name} 
          className="w-5 h-5 transition-transform group-hover:scale-110 filter brightness-75 group-hover:brightness-100"
        />
      ) : (
        <Icon size={20} className="transition-transform group-hover:scale-110" />
      )}
      <span>{name}</span>
      {isNew && (
        <span className="absolute -top-2 -right-2 bg-blue-700 text-white text-xs px-2 py-0.5 rounded-full animate-pulse">
          New!
        </span>
      )}
    </a>
  </div>
);

export default AboutCosmicQuests;