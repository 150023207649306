const SavedAnswerNotification = () => (
    <div className="mb-6 p-4 bg-indigo-900 bg-opacity-50 rounded-lg border border-indigo-700">
      <div className="flex items-center mb-2">
        <div className="w-2 h-2 bg-purple-500 rounded-full animate-pulse mr-2"></div>
        <h2 className="text-lg font-semibold text-purple-300 leading-none mt-[10px]">Answer Saved!</h2>
      </div>
      <p className="text-gray-300 text-sm">
        Your response is waiting to be submitted. Complete authentication to submit your quest!
      </p>
    </div>
  );
  
  export default SavedAnswerNotification;